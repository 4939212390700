/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyC3Lv3LIQy9VrETiMWvCxsKvD1PW3ATD7M",
  "appId": "1:1093671078668:web:d0081519dcd2ea47101729",
  "authDomain": "schooldog-i-pelhamcity-al.firebaseapp.com",
  "measurementId": "G-NYFXC7ZTRJ",
  "messagingSenderId": "1093671078668",
  "project": "schooldog-i-pelhamcity-al",
  "projectId": "schooldog-i-pelhamcity-al",
  "showGaBranding": false,
  "storageBucket": "schooldog-i-pelhamcity-al.appspot.com"
}
